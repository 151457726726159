/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #01121c;
  padding: 10px 20px;
  position: relative; /* Set relative positioning for absolute children */
  z-index: 1000; /* Ensure navbar stays above other content */
}

.navbar-logo {
  width: 100%; /* Adjust as needed */
  max-width: 150px; /* Set a max width for larger screens */
  height: auto; /* Maintain aspect ratio */
}

.nav-links {
  list-style: none; /* Remove default list styles */
  display: flex; /* Display links in a row */
  gap: 15px; /* Space between links */
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #f0eeee;
  font-weight: 600;
  font-size: large;
}

.nav-links a:hover {
  color: #fff;
}

.contact-btn {
  position: fixed;
  top: 0px;
  right: 20px;
  z-index: 1000;
}

.contact-link {
  background-color: rgb(6, 56, 72);
  padding: 10px 15px;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.contact-link:hover {
  background-color: #13a66b;
}

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Hamburger menu */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .nav-links {
      display: none; /* Hide links by default */
      flex-direction: column; /* Stack links vertically */
      position: absolute; /* Position the dropdown */
      background-color: #01630ef2; /* Match navbar background */
      top: 60px; /* Position below the navbar */
      right: 0; /* Align to the right */
      width: 70%; /* Full width */
      padding: 10px;
      border-radius: 15px 50px 30px;
      z-index: 999; /* Ensure links are above other content */
  }

  .nav-links.active {
      display: flex; /* Show links when active */
  }

  .hamburger {
      display: flex; /* Show hamburger on mobile */
  }

  .hamburger.active + .nav-links {
      display: flex; /* Show links when hamburger is active */
  }
}