/* About Us Page Styles */
.about-page {
  padding: 50px 20px;
  /* I want the background color to be light green */
  background-color: #f0f1f0;
  /*i want the text color to be black*/
  color: #000;

}

.about-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-page p {
  font-size: 21px;
  line-height: 1.5;
  color: #000000;
}

@media (max-width: 768px) {
  .about-page h1 {
    font-size: 2rem;
  }
  .about-page p {
    font-size: 1rem;
  }
}
