/* Services Page Styles */
.services-page {
  padding: 50px 20px;
  /*I want the background color to be light blue*/
  background-color: #f0f1f0;
}


.search-bar {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  border-color: black;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: box-shadow 0.3s ease;
  border-color: black;
  border-radius: 6px;

}

.search-input:focus {
  box-shadow: 0 0 8px rgba(1, 95, 195, 0.4);
}

.contact-link {
  margin-bottom: 10px;
}


.services-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.services-page ul {
  list-style: none;
  padding: 0;
}

.services-page li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* For Mobile Screens */
@media (max-width: 768px) {
  .grid-container-wrapper {
    padding: 0; /* Reduce extra padding */
    width: 100%;
  }

  .grid-container {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 15px; /* Spacing between items */
    width: 100%; /* Utilize full width */
    padding: 10px; /* Padding around grid */
  }


.search-bar {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  border-color: black;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: box-shadow 0.3s ease;
  border-color: black;
  border-radius: 6px;

}

.search-input:focus {
  box-shadow: 0 0 8px rgba(1, 95, 195, 0.4);
}

.contact-link {
  margin-bottom: 10px;
}


  .gallery {
    width: 90%; /* Occupy full width with slight margin */
    max-width: 400px; /* Limit the width to prevent overly large cards */
    margin: 0 auto; /* Center the cards horizontally */
    border: 1px solid #ccc; /* Optional border for aesthetics */
    border-radius: 10px; /* Rounded corners for cards */
    overflow: hidden; /* Handle any overflow content */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: center; /* Align text in the center */
    padding: 10px; /* Padding inside the card */
  }

  .gallery img {
    width: 100%; /* Scale image to fit card */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space between image and content */
    border-radius: 8px; /* Round the corners of images */
  }

  .gallery h2 {
    font-size: 1.2rem; /* Adjust heading size for mobile */
    margin-bottom: 5px; /* Space between heading and description */
  }

  .gallery h3 {
    font-size: 1rem; /* Adjust description size for mobile */
    color: #555; /* Subtle text color for readability */
  }
}
