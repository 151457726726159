/* JobListingsPage.css */
.job-listings-page {
    padding: 20px;
    text-align: center;
  }
  
  .jobs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .job-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: 300px;
  }
  
  .apply-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #021b36;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .employee-signin-container {
    margin-top: 40px;
  }
  
  .employee-signin-button {
    padding: 10px 20px;
    color: rgb(1, 30, 13);
    text-decoration: none;
    border-radius: 5px;
  }
  