/* Global App Styles */
.App {
  text-align: center;
  background-color: white;
  color: #333;
  font-family: Arial, sans-serif;
}

@media (max-width: 768px) {
  .App {
    padding: 0 20px;
  }
}

@media (max-width: 320px) {
  .App {
    padding: 0 10px;
  }
}

