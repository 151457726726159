/* careerspage1.css */
.careers-page {
  padding: 20px;
}

.careers-form {
  max-width: 600px;
  margin: 0 auto;
}

.careers-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.careers-form input,
.careers-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
}

.careers-form .error {
  border-color: red;
}

.error-message {
  color: red;
  margin-top: -10px;
  margin-bottom: 10px;
}

.contact-link {
  padding: 10px 20px;
  background-color: #021b36;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
}
