
.footer {
  background-color:  rgb(2, 27, 35);
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  padding: 1em 0;
  /* Flex-grow ensures that footer stays at bottom */
  margin-top: auto;
  flex-direction: row; /* Stack items vertically */
  width: 100%;
  min-height: 100px; /* Ensure footer has minimum height */
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 15px;
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.footer-links a:hover {
  color: #333;
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .footer-links a {
    display: block;
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 2em;
  
}

.footer-column {
  flex: 1;
  margin: 0 1em;
}

.footer-column h3 {
  margin-bottom: 1em;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 0.5em;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

/* Global Styles */
.footer {
  background-color: rgb(1, 14, 19);
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  padding: 1em 0;
  margin-top: auto;
  flex-direction: row;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
}

.footer-links {
  display: flex;
  flex-wrap: wrap; /* Adjusts for smaller screens */
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 15px;
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.footer-links a:hover {
  color: #333;
}

/* Responsive Design for Footer */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .footer-links a {
    display: block;
    margin-bottom: 10px;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
  }
}

.footer-column h3 {
  margin-bottom: 1em;
}

.footer-column ul {
  list-style: none;
  padding-left: 40px;
  padding-right: 40px;

}

.footer-column ul li {
  margin-bottom: 0.5em;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

