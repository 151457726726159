/* Home Page Styles */
.homepage {
  padding: 50px 20px;
  text-align: center; /* Center align text for better aesthetics */
}
.main-quote {
  position: relative; /* Needed for positioning the pseudo-elements */
  background-color: #011831; /* Blue background */
  color: white; /* White text color */
  font-size: 40px; /* Font size for the quote */
  padding: 20px; /* Padding around the quote */
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px; /* Spacing below the quote */
  border-radius: 8px; /* Optional: Rounded corners */
}

.homepage t1 {
  font-size: 5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepage h1 {
  font-size: 2.0rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.homepage h2 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.results-button {
  font-size: 1.3rem;
  margin-bottom: 0px;
  font-weight: bold;
  color: white;
  background-color: #011831;
  border-radius: 50px;          /* Rounded corners */
  padding: 20px 30px;           /* Space inside the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow */
  display: inline-block;        /* Card wraps only around content */
  max-width: 80%;             /* Optional: Limit width to control card size */
  text-align: center;           /* Center-align text */
  transition: background-color 0.3s;
}

.results-button:hover {
  background-color: #093869; /* Darker blue on hover */
}

a:link {
  text-decoration: none;
}

a:link, a:visited {
  color: white;
}

.homepage p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #01000f;
  font-weight: bold;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center images */
  margin: 20px 0;
}

.images-container img {
  width: 100%;
  max-width: 300px; /* Set a max width for images */
  margin: 10px; /* Add some spacing between images */
  border-radius: 8px; /* Optional: Add rounded corners */
}


.images-container-big {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center images */
  margin: 20px 0;
  width: 100%
}

.images-container img {
  width: 100%;
  max-width: 300px; /* Set a max width for images */
  margin: 10px; /* Add some spacing between images */
  border-radius: 8px; /* Optional: Add rounded corners */
}




.call-button-container {
  margin-top: 20px;
}

.call-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.5rem;
  color: white;
  background-color: #021b36; /* Bootstrap primary color */
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.call-button:hover {
  background-color: #093869; /* Darker blue on hover */
}

@media (max-width: 768px) {
  .homepage t1 {
    font-size: 200%;
    margin-bottom: 10px;
    font-weight: bold; 
  }
  .homepage h1 {
    font-size: 150%;
  }
  .homepage h2 {
    font-size: 150%;
  }
  .homepage p {
    font-size: 100%;
  }
  .call-button {
    font-size: 1rem;
    padding: 10px 20px; /* Reduce padding on smaller screens */
  }
}
/* src\components\CompCSS\PlayfairDisplay-Italic-VariableFont_wght.ttf */
@font-face {
  font-family: 'Playfair Display';
  src: url('./fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('./fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
.hero-section {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  position: relative;
  overflow: hidden; /* Hide overflow to prevent content from spilling out */
}

.hero-text {
  flex: 1.5; /* Allow text to take up available space */
  z-index: 1; /* Ensure text is above the image */
  text-align: center;
  padding-right: 20px; /* Space between text and image */
  color: rgb(6, 0, 0);
  position: relative; /* For absolute positioning of overlapping text */
}

.hero-title {
  font-size: 15vw; /* Adjusts size based on viewport width */
  font-family: 'Playfair Display', serif; /* Use the custom font */
  font-weight: bold; /* Adjust weight if needed */
  color: rgb(6, 0, 0);
  margin: 10;
}

.hero-sub-title {
  font-size: 15vw; /* Adjusts size based on viewport width */
  color: rgb(0, 0, 0);
  margin: 0;
  font-weight: bolder;
}

.hero-image {
  flex: 1; /* Allow image to take up available space */
  position: relative;
  max-width: 50%; /* Ensure image only takes up half the screen */
  
}

.hero-image img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  object-fit: cover;
  border-radius: 8px;
}

/* Media Queries for Overlapping Effect on Mobile */
@media (max-width: 768px) {
  .hero-section {
      flex-direction: column; /* Stack elements vertically */
  }

  .hero-text {
      position: relative; /* Change to relative for mobile */
      text-align: center; /* Center text */
      padding: 20px; /* Add padding for better readability */
      z-index: 2; /* Ensure text is above the image */
  }

  .hero-image {
      max-width: 100%; /* Allow image to take full width */
      height: auto; /* Maintain aspect ratio */
      
  }

  .hero-title {
      color: rgb(6, 0, 0);
      font-size: 5rem; /* Adjust font size for mobile */
  }

  .hero-sub-title {
      margin-top: 20px; /* Add space above the subtitle */
      font-size: 1.5rem; /* Adjust font size for subtitle */
      color: rgb(0, 0, 0); /* Ensure subtitle color is set */
  }

  .call-button-container {
      margin-top: 20px; /* Space between call button and other elements */
  }
}

/* Wrapper container for both sections */
.side-by-side-container {
  display: flex;
  flex-direction: column; /* Stack vertically by default */
  align-items: center; /* Center-align content */
  justify-content: center; /* Vertically center content */
  gap: 5px; /* Add space between elements */
  padding: 20px; /* Add padding inside the container */
  background-color: #f9f9f9; /* Subtle background color for contrast */
  border-radius: 12px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

/* Styling for larger screens */
@media (min-width: 768px) {
  .side-by-side-container {
    flex-direction: row; /* Display side by side */
    align-items: flex-start; /* Align items to the top */
    justify-content: space-between; /* Space out elements */
    padding: 40px; /* Add more padding on large screens */
    gap: 50px; /* Increase spacing between the sections */
  }

  .quote-container-side-by-side {
    flex: 1; /* Take equal space */
    padding: 20px; /* Padding inside the container */
    margin-right: 20px; /* Add space to the right */
    text-align: left; /* Align text to the left */
    border-radius: 8px; /* Rounded corners */
    background-color: #ffffff; /* White background for text area */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  }

  .images-container-side-by-side {
    flex: 1; /* Take equal space */
    text-align: center; /* Center-align content */
    padding: 20px; /* Add padding around the image container */
    border-radius: 8px; /* Rounded corners */
    background-color: #ffffff; /* White background for image container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  }

  .images-container-side-by-side img {
    max-width: 100%; /* Ensure image doesn't exceed container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: Rounded corners for the image */
  }
}

/* Mobile-Specific Styling */
@media (max-width: 768px) {
  .quote-container-side-by-side,
  .images-container-side-by-side {
    width: 100%; /* Full width for each section */
    text-align: center; /* Center-align content */
    padding: 9px; /* Add more padding on mobile */
    margin-bottom: 20px; /* Add spacing between stacked sections */
    border-radius: 8px; /* Rounded corners */
    background-color: #ffffff; /* White background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  .images-container-side-by-side img {
    max-width: 100%; /* Ensure image scales properly */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
    border-radius: 8px; /* Rounded corners for the image */
  }
}